body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  tab-size: 2;
  white-space: normal;
  display:block;
  word-wrap:break-word;
}

/* This is for the editor itself, don't worry about it for now
.penis .rc-md-editor .editor-container .sec-md .input {
  background: #333;
}
*/

.penis .rc-md-editor {
/*  border: 1px solid #222; 
  border-top-style: none; */
  border-style: none;
 /* border-bottom: 1px solid #222; */

  background: #555 /* Bottom border, huh? */
}

pre[class^="jss"] code.hljs { display: inline; padding: 2px; }
pre[class^="makeStyles-inline"] code.hljs { display: inline; padding: 2px; }

.my-custom-html-style { background-color: #555; color: #fff };
.my-custom-html-style h1 {
    font-size: 32px;
    padding: 0px;
    border: none;
    font-weight: 700;
    margin: 32px 0;
    line-height: 1.2;
  }
  .my-custom-html-style  h2 {
    font-size: 24px;
    padding: 0px 0;
    border: none;
    font-weight: 700;
    margin: 24px 0;
    line-height: 1.7;
  }
  .my-custom-html-style h3 {
    font-size: 18px;
    margin: 18px 0;
    padding: 0px 0;
    line-height: 1.7;
    border: none;
  }
  .my-custom-html-style  p {
    font-size: 14px;
    line-height: 1.7;
    margin: 8px 0;
  }
  .my-custom-html-style a {
    color: #56e28c
  }
  .my-custom-html-style a:hover {
    text-decoration: none
  }
  .my-custom-html-style  strong {
    font-weight: 700
  }
  .my-custom-html-style ol,
  .my-custom-html-style ul {
    font-size: 14px;
    line-height: 28px;
    padding-left: 36px
  }
  .my-custom-html-style  li {
    margin-bottom: 8px;
    line-height: 1.7;
  }
  .my-custom-html-style  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .my-custom-html-style .my_pre {
    display: block;
    background-color: #282c34;;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 0;
    overflow-x: auto;
    word-break: break-word;    
  }
  .my-custom-html-style .my_code {
    background-color: #282c34;
    border-radius: 0;
    padding: 3px 0;
    margin: 0;
    font-size: 14px;
    overflow-x: auto;
    word-break: normal;
  }

  .my-custom-html-style  pre {
    display: block;
    background-color: #282c34;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 0;
    overflow-x: auto;
    word-break: break-word;
  }
  .my-custom-html-style code {
    background-color: #282c34;
    border-radius: 0;
    padding: 3px 0;
    margin: 0;
    font-size: 14px;
    overflow-x: auto;
    word-break: normal;
  }
  .my-custom-html-style  code:after,
  .my-custom-html-style  code:before {
    letter-spacing: 0
  }
  .my-custom-html-style   blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(21, 21, 22, 0.05);
    border: none;
    color: #cccccc;
    border-left: 10px solid #000;
  }
  .my-custom-html-style  img,
  .my-custom-html-style  video {
    max-width: 100%;
  }
  .my-custom-html-style   table {
    font-size: 14px;
    line-height: 1.7;
    max-width: 100%;
    overflow: auto;
    border: 1px solid #f6f6f6;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
  }
  .my-custom-html-style   table td,
  .my-custom-html-style   table th {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal
  }
  .my-custom-html-style   table tr {
    border: 1px solid #efefef
  }
  .my-custom-html-style   table tr:nth-child(2n) {
    background-color: transparent
  } 
  .my-custom-html-style   table th {
    text-align: center;
    font-weight: 700;
    border: 1px solid #efefef;
    padding: 10px 6px;
    background-color: #444;
    word-break: break-word;
  }
  .my-custom-html-style  table td {
    border: 1px solid #efefef;
    text-align: left;
    padding: 10px 15px;
    word-break: break-word;
    min-width: 60px;
  }

/* Do not have light mode currently, but this would be a way to update it. However react markdown doesn't easily support it.
  .my-custom-html-style-light { color: #333 };
  .my-custom-html-style-light h1 {
      font-size: 32px;
      padding: 0px;
      border: none;
      font-weight: 700;
      margin: 32px 0;
      line-height: 1.2;
    }
    .my-custom-html-style-light  h2 {
      font-size: 24px;
      padding: 0px 0;
      border: none;
      font-weight: 700;
      margin: 24px 0;
      line-height: 1.7;
    }
    .my-custom-html-style-light h3 {
      font-size: 18px;
      margin: 18px 0;
      padding: 0px 0;
      line-height: 1.7;
      border: none;
    }
    .my-custom-html-style-light  p {
      font-size: 14px;
      line-height: 1.7;
      margin: 8px 0;
    }
    .my-custom-html-style-light a {
      color: #0052d9
    }
    .my-custom-html-style-light a:hover {
      text-decoration: none
    }
    .my-custom-html-style-light  strong {
      font-weight: 700
    }
    .my-custom-html-style-light ol,
    .my-custom-html-style-light ul {
      font-size: 14px;
      line-height: 28px;
      padding-left: 36px
    }
    .my-custom-html-style-light  li {
      margin-bottom: 8px;
      line-height: 1.7;
    }
    .my-custom-html-style-light  hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0;
      border-top: 1px solid #eee;
    }
  
    .my-custom-html-style-light .my_pre {
      display: block;
      background-color: #ffffff;
      padding: 20px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 0;
      overflow-x: auto;
      word-break: break-word;    
    }
    .my-custom-html-style-light .my_code {
      background-color: #ffffff;
      border-radius: 0;
      padding: 3px 0;
      margin: 0;
      font-size: 14px;
      overflow-x: auto;
      word-break: normal;
    }
  
    .my-custom-html-style-light  pre {
      display: block;
      background-color: #f5f5f5;
      padding: 20px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 0;
      overflow-x: auto;
      word-break: break-word;
    }
    .my-custom-html-style-light code {
      background-color: #f5f5f5;
      border-radius: 0;
      padding: 3px 0;
      margin: 0;
      font-size: 14px;
      overflow-x: auto;
      word-break: normal;
    }
    .my-custom-html-style-light  code:after,
    .my-custom-html-style-light  code:before {
      letter-spacing: 0
    }
    .my-custom-html-style-light   blockquote {
      position: relative;
      margin: 16px 0;
      padding: 5px 8px 5px 30px;
      background: none repeat scroll 0 0 rgba(102, 128, 153, .05);
      border: none;
      color: #333;
      border-left: 10px solid #D6DBDF;
    }
    .my-custom-html-style-light  img,
    .my-custom-html-style-light  video {
      max-width: 100%;
    }
    .my-custom-html-style-light   table {
      font-size: 14px;
      line-height: 1.7;
      max-width: 100%;
      overflow: auto;
      border: 1px solid #f6f6f6;
      border-collapse: collapse;
      border-spacing: 0;
      box-sizing: border-box;
    }
    .my-custom-html-style-light   table td,
    .my-custom-html-style-light   table th {
      word-break: break-all;
      word-wrap: break-word;
      white-space: normal
    }
    .my-custom-html-style-light   table tr {
      border: 1px solid #efefef
    }
    .my-custom-html-style-light   table tr:nth-child(2n) {
      background-color: transparent
    } 
    .my-custom-html-style-light   table th {
      text-align: center;
      font-weight: 700;
      border: 1px solid #efefef;
      padding: 10px 6px;
      background-color: #f5f7fa;
      word-break: break-word;
    }
    .my-custom-html-style-light  table td {
      border: 1px solid #efefef;
      text-align: left;
      padding: 10px 15px;
      word-break: break-word;
      min-width: 60px;
    }
*/  
